<template>
  <div class="justify-center w-full h-full py-md-4 d-flex align-center">
    <v-card
      v-if="company && activeStep"
      style="z-index: 2"
      class="pa-4"
      :class="{'rounded-0 h-full': $vuetify.breakpoint.smAndDown}"
    >
      <v-card-text
        class="d-flex flex-column align-center registration-container"
      >
        <v-img
          alt="Firmen Logo"
          :src="require('@/../public/img/logo.png')"
          :max-height="100"
          contain
        />
        <v-stepper
          v-if="isSelfOnboarding"
          v-model="step"
          non-linear
          alt-labels
          elevation="0"
          class="stepper"
        >
          <v-stepper-header
            class="flex-nowrap flat"
          >
            <template
              v-for="({title}, index) in steps"
            >
              <v-stepper-step
                :key="title"
                :complete="step>index+1"
                class="stepper-head"
                edit-icon="$complete"
                :step="index + 1"
              >
                {{ title }}
              </v-stepper-step>
              <v-divider
                v-if="index !== steps.length - 1"
                :key="index"
              />
            </template>
          </v-stepper-header>

          <v-stepper-items class="justify-center d-flex stepper-items">
            <v-stepper-content
              class="stepper-content"
              step="1"
            >
              <div />
            </v-stepper-content>

            <v-stepper-content
              class="stepper-content"
              step="2"
            >
              <Website
                v-if="activeStep === 'WEBSITE'"
                :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'400px'}"
                @completed="submitWebsite"
              />
              <Billing
                v-if="activeStep === 'BILLING'"
                :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'800px'}"
                :country-code="countryCode"
                @completed="submitBilling"
              />
            </v-stepper-content>

            <v-stepper-content
              class="stepper-content"
              step="3"
            >
              <TargetAudience
                v-if="activeStep === 'TARGET_AUDIENCE'"
                :style="{'width': $vuetify.breakpoint.smAndDown ? '100%':'500px'}"
                @completed="submitTargetAudience"
              />
              <Loading
                v-if="activeStep === 'FINISHED'"
              />
              <Error
                v-if="activeStep==='ERROR'"
                style="width: 400px"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <div
          v-else
          style="width:300px"
          class="justify-center d-flex flex-column"
        >
          <v-alert
            class="mt-4"
            icon="mdi-check"
            outlined
          >
            Sie haben die Registrierung erfolgreich abgeschlossen.
          </v-alert>
          <v-btn
            to="/dashboard"
            color="primary"
          >
            Weiter zur App
          </v-btn>
        </div>

        <div class="mt-8 d-flex flex-column flex-md-row align-center">
          <a
            :href="publicDataPrivacyUrl"
            target="_blank"
            class="my-2 my-md-0"
          >
            Datenschutz
          </a>
          <span class="mx-2 d-none d-md-block">|</span>
          <Feature
            v-slot="{feature}"
            :feature-slug="featureNames.DATA_PRIVACY"
          >
            <div
              v-if="feature.config.hasConditions"
              class="d-flex flex-column flex-md-row "
            >
              <a
                :href="feature.config.conditionsUrl"
                target="_blank"
                class="my-2 my-md-0"
              >
                Nutzungs und Lizenzbedingungen
              </a>
              <span class="mx-2 d-none d-md-block">|</span>
            </div>
          </Feature>
          <a
            :href="imprintUrl"
            target="_blank"
            class="my-2 my-md-0"
          >
            Impressum
          </a>
        </div>
      </v-card-text>
    </v-card>
    <GradientBackground
      :top-left="gradientColors.start"
      :top-right="gradientColors.end"
      :bottom-left="gradientColors.start"
      :bottom-right="gradientColors.end"
      style="z-index: 1"
    />
  </div>
</template>

<script>
import GradientBackground from '@/components/GradientBackground.vue'
import brandingMixin from '@/mixins/branding'
import Website from './Website.vue'
import TargetAudience from './TargetAudience.vue'
import Loading from './Loading.vue'
import Error from './Error.vue'
import Billing from './Billing.vue'
import featureMixin from '@/mixins/feature'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import COMPANY_STATE from '@/guards/CompanyState.gql'
import GET_CURRENT_ONBOARDING_STEP from './queries/GetCurrentOnboardingStep.gql'

const stepperToStep = {
  WEBSITE: 2,
  BILLING: 2,
  TARGET_AUDIENCE: 3,
  FINISHED: 3
}
export default {
  components: {
    GradientBackground,
    Website,
    TargetAudience,
    Loading,
    Billing,
    Error,
    Feature
  },
  mixins: [featureMixin, brandingMixin],
  data () {
    return {
      step: 2,
      activeStep: null,
      countryCode: 'de',
      featureNames
    }
  },
  computed: {
    isSelfOnboarding () {
      return this.company?.state?.stringValues?.includes('SELF_ONBOARDING')
    },
    steps () {
      const steps = [
        { title: 'Registrierung' },
        { title: 'Konfiguration' },
        { title: 'Lead-Generierung' }
      ]
      return steps.map((step, index) => ({ ...step, step: index + 1 }))
    }
  },
  watch: {
    activeStep () {
      this.step = stepperToStep[this.activeStep]
    }
  },
  methods: {
    submitWebsite (countryCode) {
      this.countryCode = countryCode
      this.fetchCurrentOnboardingStep()
    },
    submitBilling () {
      this.fetchCurrentOnboardingStep()
    },
    submitTargetAudience () {
      this.$router.push('/dashboard')
    },
    fetchCurrentOnboardingStep () {
      this.$apollo.queries.getCurrentOnboardingStep.refetch()
    }
  },
  apollo: {
    company: {
      query: COMPANY_STATE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      }
    },
    getCurrentOnboardingStep: {
      query: GET_CURRENT_ONBOARDING_STEP,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      },
      update ({ getCurrentOnboardingStep }) {
        this.activeStep = getCurrentOnboardingStep.name
      }
    }
  }
}
</script>

<style scoped>
.registration-container{
  overflow: scroll;
  overflow-x: hidden;
}
.flat{
  box-shadow: none;
  -webkit-box-shadow: none;
}

.stepper-content{
  min-width: 300px;
  max-width: 100%;
  padding: 0px;
}

@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
</style>
