<template>
  <div class="text-center mt-4">
    <span class="title">14 Tage kostenlos testen!</span>
    <p class="text-body-2 mb-8">
      Dafür brauchen wir nur wenige Angaben.
    </p>
    <LinkData
      v-bind="{links}"
      :hidden-field-names="['about']"
      :has-debounce-timer="false"
      :trigger-validation="triggerLinkValidation"
      @resetTriggerValidation="setTriggerLinkValidation(false)"
      @updateSettings="updateLinks"
      @validated="submit"
    />
    <v-row class="ma-0 pa-0">
      <v-select
        v-model="countryCode"
        outlined
        label="Land"
        :items="countries"
      />
    </v-row>
    <v-spacer />
    <v-btn
      class="w-full primary ma-0 pa-0"
      @click="setTriggerLinkValidation(true)"
    >
      Kostenlos testen
    </v-btn>
  </div>
</template>

<script>
import LinkData from '@/components/forms/LinkData.vue'
import brandingMixin from '@/mixins/branding'
import { COUNTRIES } from '@/components/forms/labels'
import COMPLETE_ONBOARDING_STEP_WEBSITE from './queries/CompleteOnboardingStepWebsite.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: {
    LinkData
  },
  mixins: [brandingMixin],

  data () {
    return {
      links: {
        website: 'https://www.',
        privacy: 'https://www.',
        imprint: 'https://www.'
      },
      countryCode: 'DE',
      countries: COUNTRIES,
      triggerLinkValidation: false
    }
  },
  methods: {
    updateLinks (links) {
      this.links = { ...links }
    },
    setTriggerLinkValidation (trigger) {
      this.triggerLinkValidation = trigger
    },
    async submit () {
      try {
        const { data: { completeOnboardingStepWebsite } } = await this.$apollo.mutate({
          mutation: COMPLETE_ONBOARDING_STEP_WEBSITE,
          variables: {
            input: {
              links: this.links,
              countryCode: this.countryCode.toLowerCase()
            }
          }
        })
        if (completeOnboardingStepWebsite.status === 'SUCCESS') {
          this.$emit('completed', this.countryCode.toLowerCase())
        }

        if (completeOnboardingStepWebsite.status === 'ERROR') {
          if (completeOnboardingStepWebsite.errorCode === 'INVALID_WEBSITE_LINKS') {
            bus.$emit(
              eventNames.SHOW_SNACKBAR,
              {
                color: 'error',
                text: 'Mindestens einer der angegebenen Links ist nicht gültig. Bitte überprüfen Sie Ihre Eingaben.'
              }
            )
          } else {
            bus.$emit(
              eventNames.SHOW_SNACKBAR,
              {
                color: 'error',
                text: 'Es gab leider ein technisches Problem beim speichern Ihrer Daten. Wir kümmern uns darum!'
              }
            )
          }
        }
      } catch (error) {
        bus.$emit(
          eventNames.SHOW_SNACKBAR,
          {
            color: 'error',
            text: 'Es gab leider ein technisches Problem beim speichern Ihrer Daten. Wir kümmern uns darum!'
          }
        )
      }
    }
  }

}
</script>

<style>

</style>
