<template>
  <v-card
    class="grey lighten-3"
    style="width:350px"
    flat
  >
    <v-card-text class="mb-4">
      <span class="skyLight pa-1 font-16">{{ trialDurationDays }} Tage kostenlos,</span> <br> danach (ab {{ chargableFromDate }}):

      <div class="mt-6 mb-4 d-flex align-center font-16">
        <div :class="{ 'font-weight-bold': !isYearly }">
          monatlich
        </div>
        <v-switch
          v-model="isYearly"
          class="mx-2"
        />
        <div>
          <v-badge
            content="20% Rabatt"
            color="warning"
            offset-x="-10"
            offset-y="16"
          >
            <span :class="{ 'font-weight-bold': isYearly }">
              jährlich
            </span>
          </v-badge>
        </div>
      </div>

      <div class="">
        <span class="font-weight-bold">
          {{ priceFormatted }}
        </span>/ {{ isYearly ? 'Jahr' : 'Monat' }}
        <span v-if="isYearly">(<strong>{{ yearlySavingsFormatted }}</strong> gespart!)</span>
      </div>

      <div class="mt-4">
        Nach Ende der Testphase beträgt die Mindestlaufzeit 12 Monate.
      </div>
    </v-card-text>

    <v-card-text class="skyLight">
      <ul class="py-4 condition-list my-n4 font-weight-bold">
        <li class="my-6 d-flex align-start">
          <v-icon
            color="dogerBlue"
            class="mr-4 white"
            style="border-radius: 30px"
          >
            mdi-check-circle
          </v-icon>

          <div>
            Ihre Kreditkarte wird in der {{ trialDurationDays }}-tägigen Testphase nicht belastet.
          </div>
        </li>
        <li class="my-6 d-flex align-start">
          <v-icon
            color="dogerBlue"
            class="mr-4 white"
            style="border-radius: 30px"
          >
            mdi-check-circle
          </v-icon>
          <div>
            Sofortstart für Ihren Akquise-Boost.
          </div>
        </li>
        <li class="my-6 d-flex align-start">
          <v-icon
            color="dogerBlue"
            class="mr-4 white pa-0"
            style="border-radius: 30px"
          >
            mdi-check-circle
          </v-icon>
          <div>
            In der {{ trialDurationDays }}-tägigen Testphase können Sie jederzeit kündigen.
          </div>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
import feature from '@/mixins/feature'

const TRIAL_DURATION_DAYS = 14

export default {
  mixins: [feature],
  props: {
    countryCode: {
      type: String,
      required: true,
      // Normally our countryCode is lowercase, but in the context of Billwerk it is uppercase.
      validator: (value) => value.toUpperCase() === value
    },
    isYearlyPreselected: {
      type: Boolean,
      default: () => true
    }
  },

  data () {
    return {
      trialDurationDays: TRIAL_DURATION_DAYS,
      isYearly: this.isYearlyPreselected
    }
  },

  computed: {
    billwerkFeature () {
      return this.$features.feature(this.featureNames.BILLWERK)?.config
    },
    priceLists () {
      return this.billwerkFeature.priceLists
    },
    planVariants () {
      return this.billwerkFeature.planVariants
    },
    priceListId () {
      return this.getPriceListId(this.countryCode)
    },
    planVariantId () {
      return this.getPlanVariantId(this.isYearly)
    },

    chargableFromDate () {
      const date = new Date()
      date.setDate(date.getDate() + this.trialDurationDays)
      return date.toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    },

    priceFormatted () {
      const price = this.getPrice()
      return this.formatPrice(price)
    },

    yearlySavings () {
      const yearlyPrice = this.getPrice('yearly')
      const monthlyPrice = this.getPrice('monthly')
      return monthlyPrice * 12 - yearlyPrice
    },

    yearlySavingsFormatted () {
      return this.formatPrice(this.yearlySavings)
    }
  },

  watch: {
    priceListId (newPriceListId) {
      this.$emit('priceListIdChange', newPriceListId)
    },

    planVariantId (newPlanVariantId) {
      this.$emit('planVariantIdChange', newPlanVariantId)
    }
  },

  mounted () {
    // Initially emit cart to parent component
    this.$emit('priceListIdChange', this.priceListId)
    this.$emit('planVariantIdChange', this.planVariantId)
  },

  methods: {
    getPriceListId (countryCode) {
      return this.priceLists[countryCode]?.id ?? this.priceLists.default.id
    },

    getPlanVariantId (isYearly) {
      if (isYearly) {
        return this.planVariants.yearly.id
      } else {
        return this.planVariants.monthly.id
      }
    },

    getCurrency () {
      return this.priceLists[this.countryCode]?.currency || this.priceLists.default.currency
    },

    getPrice (billingFrequency = null) {
      if (!billingFrequency) {
        billingFrequency = this.isYearly ? 'yearly' : 'monthly'
      }
      const prices = this.planVariants[billingFrequency].prices
      const price = prices[this.countryCode] || prices.default
      return price
    },

    formatPrice (price) {
      return Intl.NumberFormat(`de-${this.countryCode.toUpperCase()}`, {
        style: 'currency',
        currency: this.getCurrency(),
        minimumFractionDigits: Number.parseInt(price) === price ? 0 : 2
      }).format(price)
    }
  }
}
</script>

<style scoped>
.condition-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.font-16 {
  font-size: 18px;
}
</style>
