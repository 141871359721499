<template>
  <v-form
    v-model="isValid"
    @submit.prevent="submit"
  >
    <div ref="iframeWrapper" />

    <LoadingButton
      type="submit"
      color="primary"
      :disabled="!isValid"
      :loading="isSubmitting"
      @click="submit"
    >
      Weiter
    </LoadingButton>
  </v-form>
</template>

<script>
import LoadingButton from '@/components/loading-button.vue'
import subscriptionJsMixin from '../subscriptionJsMixin'

const style = {
  body: {
    padding: 0
  },
  input: {
    border: '1px solid #9e9e9e',
    borderRadius: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: '20px',
    padding: '8px 12px',
    height: '56px',
    fontSize: '16px',
    boxShadow: 'none'
  },
  inputInvalid: {
    borderColor: '#FA0143'
    // border: '1px solid #9e9e9e',
    // borderRadius: '10px',
    // color: 'rgba(0, 0, 0, 0.87)',
    // lineHeight: '20px',
    // padding: '8px 12px',
    // height: '56px',
    // fontSize: '16px',
    // boxShadow: 'none',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 'normal',
    fontSize: '12px'
    // backgroundColor: '#fff'
    // padding: '0 0 0 4px',
    // position: 'relative',
    // top: '15px',
    // left: '8px',
    // transform: 'translate(8px, 15px)'
  }
  // labelRequired: {
  //   fontWeight: 'normal',
  //   fontSize: '12px',
  //   backgroundColor: 'white',
  //   position: 'relative',
  //   top: '15px',
  //   left: '8px',
  //   paddingRight: '4px',
  // }
}

export default {
  components: {
    LoadingButton
  },

  mixins: [subscriptionJsMixin],

  props: {
    paymentMethod: {
      type: String,
      required: true
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isValid: false
    }
  },

  watch: {
    /**
     * This is called when the SubscriptionJS library is loaded.
     * @param {Boolean} isInitialized Is defined in subscriptionJsMixin.
     */
    isSubscriptionJsInitialized (isInitialized) {
      if (isInitialized) {
        this.paymentForm = this.SubscriptionJS.createElement(
          'paymentForm',
          this.$refs.iframeWrapper,
          this.getConfig(),
          style,
          this.handleCreateError
        )
      }
    }
  },

  methods: {
    submit () {
      this.$emit('submit', this.paymentForm)
    },

    getConfig () {
      return {
        publicApiKey: process.env.VUE_APP_BILLWERK_PUBLIC_API_KEY,
        paymentMethods: [this.paymentMethod],
        locale: 'de'
      }
    },

    handleCreateError (error) {
      this.$emit('create-error', error)
    }
  }
}
</script>

<style>
iframe {
  width: 100%;
}
</style>
