<template>
  <v-form
    ref="form"
    v-model="isValid"
    @submit.prevent="submit"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        class="pr-sm-1"
      >
        <v-text-field
          v-model="formData.firstName"
          label="Vorname"
          :rules="[rules.required]"
          required
          outlined
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="pl-sm-1"
      >
        <v-text-field
          v-model="formData.lastName"
          label="Nachname"
          :rules="[rules.required]"
          required
          outlined
        />
      </v-col>
    </v-row>

    <v-text-field
      v-model="formData.companyName"
      label="Unternehmen"
      :rules="[rules.required]"
      required
      outlined
    />

    <v-row no-gutters>
      <v-col
        cols="12"
        sm="9"
      >
        <v-text-field
          v-model="formData.address.street"
          label="Strasse"
          :rules="[rules.required]"
          required
          outlined
        />
      </v-col>

      <v-col
        cols="12"
        sm="3"
        class="pl-sm-2"
      >
        <v-text-field
          v-model="formData.address.houseNumber"
          label="Haus-Nr."
          :rules="[rules.required, rules.length(1, 10)]"
          required
          outlined
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col
        cols="12"
        sm="3"
      >
        <v-text-field
          v-model="formData.address.postalCode"
          label="PLZ"
          :rules="[rules.required, rules.zip]"
          required
          outlined
        />
      </v-col>

      <v-col
        cols="12"
        sm="9"
        class="pl-sm-2"
      >
        <v-text-field
          v-model="formData.address.city"
          label="Stadt"
          :rules="[rules.required]"
          required
          outlined
        />
      </v-col>
    </v-row>

    <v-select
      v-model="formData.address.country"
      label="Land"
      :items="countryList"
      :rules="[rules.required]"
      disabled
      outlined
      required
    />

    <v-row
      no-gutters
      align-sm="baseline"
    >
      <v-col
        cols="12"
        :sm="allowNoVatId ? 5 : 6"
      >
        <v-text-field
          v-model="formData.vatId"
          label="USt-IdNr."
          :placeholder="vatPlaceholder"
          :rules="noVatId ? [rules.vatId] : [rules.vatId, rules.required]"
          :required="!noVatId"
          :disabled="noVatId"
          outlined
          @input="updateVatId"
        />
      </v-col>

      <v-col
        v-if="allowNoVatId"
        cols="12"
        sm="7"
        class="pl-sm-2"
      >
        <v-checkbox
          v-model.lazy="noVatId"
          class="pr-4 mt-0"
          label="Ich habe noch keine USt-IdNr."
          outlined
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="pl-sm-2"
      >
        <v-text-field
          v-model="formData.couponCode"
          label="Gutschein-Code"
          placeholder=""
          outlined
        />
      </v-col>
    </v-row>

    <LoadingButton
      type="submit"
      color="primary"
      class="w-full"
      :loading="isSubmitting"
    >
      Weiter
    </LoadingButton>
  </v-form>
</template>

<script>
import { zip, empty, length, required } from '@/lib/validation'
import LoadingButton from '@/components/loading-button.vue'
import { COUNTRIES } from '@/components/forms/labels'

const VAT_ID_REGX = /^(ATU[0-9]{8}|DE[0-9]{9})$|^((CHE)(-|\s)?|)\d{3}(\.|\s)?\d{3}(\.|\s)?\d{3}(\s)?(IVA|TVA|MWST|VAT)?$/

const vatPlaceholderForCountry = {
  de: 'DE123456789',
  ch: 'CHE-123.456.789',
  at: 'ATU12345678'
}

export default {
  components: { LoadingButton },
  props: {
    value: {
      type: Object,
      required: true
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isValid: false,

      countryList: COUNTRIES,
      noVatId: false,

      /**
       * Is the user allowed to skip the VAT ID?
       * This should eventually be fetched from the feature config.
       * For now it's hardcoded for the going live.
       */
      allowNoVatId: false,

      formData: {
        firstName: '',
        lastName: '',
        companyName: '',
        vatId: '',
        couponCode: '',
        address: {
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          country: ''
        }
      },

      rules: {
        zip,
        length,
        required,
        vatId: (value) => (
          empty(value) ||
          VAT_ID_REGX.test(value) ||
          'Bitte gültige USt-IdNr. eintragen (DE/AT/CHE + 9 stellige ID)'
        )
      }
    }
  },
  computed: {
    vatPlaceholder () {
      const country = this.formData.address.country
      return vatPlaceholderForCountry[country.toLowerCase()]
    }
  },

  watch: {
    value: {
      handler (newVal) {
        this.formData = newVal
      },
      deep: true
    },

    formData: {
      handler (newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },

  mounted () {
    this.formData = this.value
  },

  methods: {
    updateVatId (value) {
      this.formData.vatId = value.toUpperCase()
    },

    submit () {
      if (this.$refs.form.validate()) {
        this.$emit('submit')
      }
    }
  }
}
</script>
