<template>
  <div class="d-flex flex-column justify-center mt-8">
    <v-alert
      dense
      outlined
      type="error"
    >
      Leider ist etwas schief gelaufen, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
    </v-alert>

    <v-btn
      color="primary"
      to="/auth/login"
    >
      Zurück zum Login
    </v-btn>
  </div>
</template>

<script>
export default {

}
</script>
