<template>
  <v-row class="text-left">
    <v-col
      v-if="isState('finalize')"
      class="my-12"
      cols="12"
    >
      <h3 class="mb-12 text-center">
        Einen Moment bitte, wir verarbeiten gerade Ihre gemachten Eingaben.
      </h3>
      <div class="w-full d-flex justify-space-around">
        <v-progress-circular
          indeterminate
          :size="50"
          :width="5"
          color="primary"
        />
      </div>
    </v-col>

    <ProductDescription
      v-if="isState('planSelection')"
      :country-code="customer.address.country"
      @lightProductSelected="handleLightProductSelected"
    />

    <template v-if="['customerForm', 'paymentForm'].some(isState)">
      <v-col
        cols="12"
        md="5"
        order-md="2"
      >
        <Cart
          :country-code="customer.address.country"
          :is-yearly-preselected="isYearly"
          @priceListIdChange="handlePriceListIdChange"
          @planVariantIdChange="handlePlanVariantIdChange"
        />
      </v-col>

      <v-col
        ref="formSection"
        cols="12"
        md="7"
      >
        <v-alert
          v-if="isState('customerForm.error') && errorCode === 'INVALID_VAT_ID'"
          class="mb-8"
          type="error"
          text
        >
          Die eingegebene USt-IdNr. ist ungültig.<br>
          Bitte überprüfen Sie Ihre Eingaben und versuchen es noch einmal.
        </v-alert>

        <v-alert
          v-else-if="isState('customerForm.error')"
          class="mb-8"
          type="error"
          text
        >
          Es ist ein Fehler aufgetreten.<br>
          Bitte überprüfen Sie Ihre Eingaben und versuchen es noch einmal.
        </v-alert>

        <CustomerForm
          v-if="isState('customerForm')"
          v-model="customer"
          :is-submitting="isState('customerForm.submitting')"
          @submit="handleCustomerFormSubmit"
        />

        <v-alert
          v-if="isState('paymentForm.error')"
          class="mb-8"
          type="error"
          text
        >
          Es ist ein Fehler aufgetreten.<br>
          Bitte überprüfen Sie Ihre Eingaben und versuchen es noch einmal.
        </v-alert>

        <PaymentForm
          v-if="isState('paymentForm')"
          :payment-method="selectedPaymentMethod"
          :is-submitting="isState('paymentForm.submitting')"
          @submit="handlePaymentFormSubmit"
          @create-error="handlePaymentFormCreateError"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { scrollIntoViewWithOffset } from '@/lib/scrollIntoView'
import stateMixin from '@/mixins/state'
import subscriptionJsMixin from '../subscriptionJsMixin'
import Cart from './Cart.vue'
import CustomerForm from './CustomerForm.vue'
import PaymentForm from './PaymentForm.vue'
import ProductDescription from './ProductDescription.vue'
import GET_ONBOARDING_BILLING_DATA from './queries/getOnboardingBillingData.gql'
import HANDLE_ONBOARDING_BILLING_DATA from './queries/handleOnboardingBillingData.gql'
import ASSIGN_ONBOARDING_BILLING_ORDER_ID from './queries/assignOnboardingBillingOrderId.gql'

/**
 * URL query parameter for the payment callback.
 * Will be appended to the URL which is passed to the payment provider.
 */
const PAYMENT_CALLBACK_PARAM = 'paymentCallback'

const paymentMethod = {
  CREDIT_CARD: 'CreditCard:Reepay',
  APPLE_PAY: 'ApplePay:Reepay',
  DEBIT: 'Debit:FakePSP'
}

export default {
  components: {
    CustomerForm,
    PaymentForm,
    Cart,
    ProductDescription
  },

  mixins: [
    stateMixin,
    subscriptionJsMixin
  ],

  props: {
    countryCode: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      signupService: null,
      paymentService: null,

      initialState: 'planSelection',
      errorCode: null,

      /**
       * Currently we only offer payment via credit card.
       * Must be extended when we actually offer other
       * methods like debit as well.
       */
      selectedPaymentMethod: paymentMethod.CREDIT_CARD,

      /**
       * This object will be passed as is to SubscriptionJS.
       */
      cart: {
        priceListId: '',
        planVariantId: ''
      },

      /**
       * This object will be passed as is to SubscriptionJS.
       */
      customer: {
        emailAddress: this.email,
        firstName: '',
        lastName: '',
        companyName: '',
        vatId: '',
        // belongs to this.cart object, but it's collected in the customer form
        couponCode: '',
        address: {
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          country: this.countryCode.toUpperCase()
        }
      },

      /** Order object from Billwerk */
      order: null,

      isYearly: true
    }
  },

  watch: {
    /**
     * This is called when the SubscriptionJS library is loaded.
     * @param {Boolean} isInitialized Is defined in subscriptionJsMixin.
     */
    isSubscriptionJsInitialized (isInitialized) {
      if (isInitialized) {
        this.signupService = new this.SubscriptionJS.Signup()

        if (this.isState('finalize')) {
          setTimeout(this.finalize, 2000)
        }
      }
    },

    state (newState) {
      if (newState === 'completed') {
        this.$emit('completed')
      } else if (newState.match(/\.error$/)) {
        scrollIntoViewWithOffset(this.$refs.formSection, 60)
      }
    }
  },

  created () {
    if (this.isCallbackAfterPayment()) {
      this.setState('finalize')
    }
  },

  apollo: {
    customer: {
      query: GET_ONBOARDING_BILLING_DATA,
      update (data) {
        const {
          customer: {
            companyName, firstName, lastName, vatId,
            address: { street, houseNumber, postalCode, city, country }
          }
        } = data.onboardingBillingData
        return {
          emailAddress: this.email,
          companyName: companyName || this.customer.companyName,
          firstName: firstName || this.customer.firstName,
          lastName: lastName || this.customer.lastName,
          vatId: vatId || this.customer.vatId,
          address: {
            street: street || this.customer.address.street,
            houseNumber: houseNumber || this.customer.address.houseNumber,
            postalCode: postalCode || this.customer.address.postalCode,
            city: city || this.customer.address.city,
            country: country?.toUpperCase() || this.customer.address.country
          }
        }
      }
    }
  },

  methods: {
    isCallbackAfterPayment () {
      return this.$route.query[PAYMENT_CALLBACK_PARAM]
    },

    handlePriceListIdChange (priceListId) {
      this.cart.priceListId = priceListId
    },

    handlePlanVariantIdChange (planVariantId) {
      this.cart.planVariantId = planVariantId
    },

    async handleCustomerFormSubmit () {
      this.setState('customerForm.submitting')

      try {
        await this.saveBillingData()
      } catch (error) {
        this.setState('customerForm.error')
        // this.errorCode = error.code // TODO
        // eslint-disable-next-line no-console
        console.log('mutation error', error)
        return // order must not be created if we couldn't process the customer data
      }

      this.createOrder()
    },

    /**
     * Only called when SEPA is selected as payment method.
     *
     * @param {Object} paymentForm The SubscriptionJS payment form object.
     */
    handlePaymentFormSubmit (paymentForm) {
      this.setState('paymentForm.submitting')

      this.signupService.paySignupInteractive(
        null,
        paymentForm,
        this.order,
        this.handlePaySignupInteractiveSuccess,
        (error) => {
          this.setState('paymentForm.error')
          // eslint-disable-next-line no-console
          console.error('paySignupInteractive error', error)
        }
      )
    },

    /**
     * This is called when the payment form iframe could not be created.
     * The error is thrown by SubscriptionJS.
     *
     * @param {Object} error
     */
    handlePaymentFormCreateError (error) {
      this.setState('paymentForm.error')
      // eslint-disable-next-line no-console
      console.error('payment form create error', error)
    },

    async saveBillingData () {
      let result
      const input = {
        customer: {
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
          companyName: this.customer.companyName,
          address: {
            street: this.customer.address.street,
            houseNumber: this.customer.address.houseNumber,
            zip: this.customer.address.postalCode,
            city: this.customer.address.city,
            countryCode: this.customer.address.country.toLowerCase()
          }
        }
      }

      try {
        const { data } = await this.$apollo.mutate({
          mutation: HANDLE_ONBOARDING_BILLING_DATA,
          variables: { input }
        })
        result = data.result
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('graphql-error', err)
        throw new Error('graphql-error')
      }

      if (result.status === 'ERROR') {
        this.setState('customerForm.error')
      }
    },

    /**
     * This is step 1 of the subscribe process. It creates an order in Billwerk
     * which will be submitted with the payment process in second stage.
     */
    createOrder () {
      const cart = {
        ...this.cart,
        couponCode: this.customer.couponCode
      }

      const customer = {
        ...this.customer,
        // SubscriptionJS modifies the address object by reference
        // which causes empty form fields resulting in visual glitches.
        address: {
          ...this.customer.address
        },
        // Billwerk doesn't accept VAT ID with suffix
        vatId: this.customer.vatId.replace(/[a-zA-Z]+$/, '').trim()
      }

      this.signupService.createOrder(
        cart,
        customer,
        this.handleOrderCreated,
        (error) => {
          // TODO handle InactiveCouponCode error
          this.setState('customerForm.error')
          // eslint-disable-next-line no-console
          console.error('orderError', error)
          if (error.errorMessage.includes('VAT ID')) {
            this.errorCode = 'INVALID_VAT_ID'
          } else {
            this.errorCode = error.errorCode[0]
          }
        }
      )
    },

    /**
     * Success handler for `this.signupService.createOrder()`
     *
     * When the order is created, we need to trigger different payment processes
     * depending on the selected payment method.
     */
    async handleOrderCreated (order) {
      this.order = order

      try {
        await this.saveOrderId(order.OrderId)
      } catch (error) {
        this.setState('customerForm.error')
        // this.errorCode = error.code // TODO
        // eslint-disable-next-line no-console
        console.log('mutation error', error)
        return // order must not be created if we couldn't process the customer data
      }

      if (this.selectedPaymentMethod === paymentMethod.DEBIT) {
        this.setState('paymentForm.idle')
      } else {
        this.startCreditCardPaymentProcess()
      }
    },

    /**
     * We save the order ID in the database to be able to identify the order later.
     */
    async saveOrderId (orderId) {
      let result

      try {
        const { data } = await this.$apollo.mutate({
          mutation: ASSIGN_ONBOARDING_BILLING_ORDER_ID,
          variables: {
            input: { orderId }
          }
        })
        result = data.result
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('graphql-error', err)
        throw new Error('graphql-error')
      }

      if (result.status === 'ERROR') {
        this.setState('customerForm.error')
      }
    },

    /**
     * This is step 2 of the subscribe process. It initiates the payment process
     * for credit card payments.
     */
    startCreditCardPaymentProcess () {
      const paymentConfig = {
        publicApiKey: process.env.VUE_APP_BILLWERK_PUBLIC_API_KEY,
        providerReturnUrl: this.composePaymentRedirectUrl()
      }

      const paymentData = {
        bearer: this.selectedPaymentMethod
      }

      const onPaymentServiceReady = () => {
        this.signupService.paySignupInteractive(
          this.paymentService,
          paymentData,
          this.order,
          this.handlePaySignupInteractiveSuccess,
          () => {
            this.setState('customerForm.error')
          }
        )
      }

      const onError = (error) => {
        this.setState('customerForm.error')
        // eslint-disable-next-line no-console
        console.error('Payment service init error', error)
      }

      this.paymentService = new this.SubscriptionJS.Payment(
        paymentConfig,
        onPaymentServiceReady,
        onError
      )
    },

    /**
     * Success handler for `this.signupService.paySignupInteractive()`
     *
     * Depending on the chosen payment method we need to handle the following
     * process differently:
     * - For Credit Card we forward the user to the payment page to complete the payment.
     * - For debit the process is completed at this point.
     */
    handlePaySignupInteractiveSuccess (data) {
      if (data.Url) {
        window.location.href = data.Url
      } else {
        this.setState('completed')
      }
    },

    /**
     * When the user returns from the payment page we need to finalize the
     * subscription process.
     * It could even be the case that the user aborted the payment process.
     * Even then we need to call `finalize()` to clean up the subscription process.
     */
    finalize () {
      this.SubscriptionJS.finalize(
        this.handleFinalizeSuccess,
        this.handleFinalizeError
      )
    },

    /**
     * Success handler for `this.SubscriptionJS.finalize()`
     */
    handleFinalizeSuccess (data) {
      this.setState('completed')
      // We want all query params to be removed in the URL
      this.$router.replace(this.$route.name)
    },

    /**
     * Error handler for `this.SubscriptionJS.finalize()`
     */
    handleFinalizeError (error) {
      if (error.errorCode.includes('Canceled')) {
        this.setState('customerForm.idle')
        // We want all query params to be removed in the URL
        this.$router.replace(this.$route.name)
      } else {
        this.setState('customerForm.error')
      }
    },

    /**
     * Callback URL where the user is redirected to after the payment process.
     */
    composePaymentRedirectUrl () {
      const currentPath = this.$route.path
      const url = new URL(currentPath, window.location.origin)
      url.searchParams.set(PAYMENT_CALLBACK_PARAM, '1')
      return url.toString()
    },

    handleLightProductSelected (isYearly) {
      this.isYearly = isYearly
      this.setState('customerForm.idle')
    }
  }
}
</script>
