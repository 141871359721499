<!-- eslint-disable vue/no-v-html -->
<template>
  <v-col cols="12">
    <v-row>
      <v-col
        col="12"
      >
        <p class="text-center text-h6">
          Wählen Sie Ihr passendes Paket. Mit dem LIGHT können Sie sofort loslegen.
        </p>
        <div class="flex-row justify-center d-flex">
          <span :class="{ 'font-weight-bold': !isYearly, 'mr-2': true }">
            monatlich
          </span>
          <v-switch
            id="switch"
            v-model="isYearly"
            class="pt-0 mt-0 d-inline font-weight-regular"
          /><div>
            <v-badge
              content="20% Rabatt"
              color="warning"
              offset-x="-10"
              offset-y="16"
            >
              <span :class="{ 'font-weight-bold': isYearly }">
                jährlich
              </span>
            </v-badge>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="product of products"
        :key="product.productName"
        cols="12"
        md="6"
      >
        <h3 class="my-4 text-center font-weight-black text-h5">
          {{ product.productName }}
        </h3>
        <div class="product-box">
          <p
            class="text-center pa-4 product-teaser"
            v-html="product.productTeaser"
          />
          <div
            class="px-4 text-center price-wrapper"
          >
            <div class="price">
              {{ priceFormatted(product) }}
            </div>
            <p v-html="priceDescription(product.priceInfo)" />
          </div>

          <div class="pb-4 text-center cta-wrapper">
            <v-btn
              v-if="product.productName === 'LIGHT'"
              type="button"
              color="primary"
              @click="selectProduct"
            >
              jetzt starten
            </v-btn>
            <v-btn
              v-else
              type="button"
              color="primary"
              :href="calendlyLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termin vereinbaren
            </v-btn>
            <p v-if="product.priceInfo.hint">
              {{ product.priceInfo.hint }}
            </p>
          </div>
          <v-list
            class="featureList"
            dense
          >
            <v-list-item
              v-for="feature of product.includedFeatures"
              :key="feature"
            >
              <v-list-item-icon>
                <v-icon>mdi-check-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ feature }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import feature from '@/mixins/feature'

const ProductType = {
  LIGHT: 'LIGHT',
  EXPERT: 'EXPERT'
}

const products = [{
  productName: ProductType.LIGHT,
  productTeaser: 'Der Einstieg in die<br>Marketing-Welt von BOTTIMMO',
  priceInfo: {
    yearly: {
      label: 'pro Monat bei Jahreszahlung<br>12 Monate'
    },
    monthly: {
      label: 'pro Monat'
    }
  },
  includedFeatures: [
    'Tool zur Immobilienbewertung (ohne Sofortergebnis)',
    'Tool zur Bewertung von Mehrfamilienhäusern (ohne Sofortergebnis)',
    'Tool zum Sammeln von Kaufinteressenten (Wohntraumfinder)',
    'Tool zur Vereinbarung von Gesprächstermin',
    '3 Landingpages inkl. iFrame Integration',
    'Set an Werbemittel',
    'KI-Support: Texte für Social Media',
    'Lead Management System Desktop/Mobile ready',
    'Akquise-Booster: Bestandskunden-Marketing',
    'Automatische Nachfass-E-Mails',
    'Sonderkonditionen unserer Partner',
    'Webinare mit Experten',
    'Fortbildungs-, Hilfe- und Trainingsbereich'
  ]
}, {
  productName: ProductType.EXPERT,
  productTeaser: 'Alles, was Sie brauchen,<br>um die Rakete im Marketing zu zünden.',
  priceInfo: {
    yearly: {
      price: {
        CH: 699.00,
        default: 599.00
      },
      label: 'pro Monat*<br>12 Monate'
    },
    monthly: {
      price: {
        CH: 699.00,
        default: 599.00
      },
      label: 'pro Monat*<br>12 Monate'
    },
    hint: '* zzgl. Einrichtungsgebühr'
  },
  includedFeatures: [
    'Alles aus dem LIGHT Paket',
    '10+ professionelle Ratgeber für Käufer- und Verkäufer',
    '20+ Landingpages inkl. iFrame Integration',
    '4+ hilfreiche Checklisten',
    'Individualisierung aller Inhalte: Logo, Farben, Fotos',
    'Zugänge für bis zu 5 Mitarbeitern (mehr möglich)',
    'Telefonischer Support'
  ]
}]
export default {

  mixins: [feature],
  props: {
    countryCode: {
      type: String,
      required: true,
      validator: (value) => value.toUpperCase() === value
    }
  },
  data () {
    return {
      isYearly: true,
      products
    }
  },
  computed: {
    billwerkFeature () {
      return this.$features.feature(this.featureNames.BILLWERK)?.config
    },
    priceDescription () {
      return (product) => this.isYearly ? product.yearly.label : product.monthly.label
    },
    calendlyLink () {
      return 'https://calendly.com/bottimmo-marketing-experten/marketing-beratung?utm_source=register-form&utm_medium=login-app&utm_campaign=sales"'
    },
    priceLists () {
      return this.billwerkFeature.priceLists
    },
    planVariants () {
      return this.billwerkFeature.planVariants
    },
    priceListId () {
      return this.getPriceListId(this.countryCode)
    },
    planVariantId () {
      return this.getPlanVariantId(this.isYearly)
    },
    priceFormatted () {
      return (product) => {
        const price = product?.priceInfo[this.getBillingFrequency].price?.[this.getVariant] || this.getPrice()
        return this.formatPrice(price)
      }
    },
    getVariant () {
      // array can be added by other country codes, if they have different prices
      if (['CH'].includes(this.countryCode)) {
        return this.countryCode
      }
      return 'default'
    },
    getBillingFrequency () {
      return this.isYearly ? 'yearly' : 'monthly'
    }
  },
  methods: {
    selectProduct (event) {
      event.preventDefault()
      this.$emit('lightProductSelected', this.isYearly)
    },
    getCurrency () {
      return this.priceLists[this.countryCode]?.currency || this.priceLists.default.currency
    },
    getPrice (billingFrequency = null) {
      if (!billingFrequency) {
        billingFrequency = this.getBillingFrequency
      }
      const prices = this.planVariants[billingFrequency].prices
      const price = prices[this.countryCode] || prices.default
      return this.isYearly ? Math.ceil(price / 12) : price
    },
    formatPrice (price) {
      return Intl.NumberFormat(`de-${this.countryCode.toUpperCase()}`, {
        style: 'currency',
        currency: this.getCurrency(),
        minimumFractionDigits: Number.parseInt(price) === price ? 0 : 2
      }).format(price)
    }
  }
}
</script>
<style scoped>
.product-box {
  background-color: #f7f7f7;
}
.product-teaser {
  background-color: #F5F5F5;
}
.featureList {
  background-color: #f7f7f7;
}
.price {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 8px;
}
.price-wrapper {
  min-height: 90px;
}
.cta-wrapper {
  min-height: 90px;
}
</style>
