<template>
  <div class="text-center mt-4">
    <span class="title">Jetzt kann es losgehen!</span>
    <p class="text-body-1 mt-4 mb-8">
      Welche Kontakte möchten Sie als Erstes ansprechen?
    </p>
    <div
      class="d-flex flex-wrap"
      :class="{'justify-space-between':$vuetify.breakpoint.mdAndUp, 'justify-space-around': $vuetify.breakpoint.smAndDown}"
    >
      <v-btn
        color="primary"
        class="icon-button my-4"
        @click="submit('EXISTING_CONTACTS')"
      >
        <div class="d-flex flex-column fle">
          <v-icon>
            mdi-account
          </v-icon>
          <p class="my-2">
            Bestehende
          </p>
          <p>
            Kontakte
          </p>
        </div>
      </v-btn>
      <v-btn
        color="primary"
        class="icon-button my-4"
        @click="submit('NEW_CONTACTS')"
      >
        <div class="d-flex flex-column">
          <v-icon>
            mdi-account-plus
          </v-icon>
          <p class="my-2">
            Neue
          </p>
          <p>
            Kontakte
          </p>
        </div>
      </v-btn>
      <v-btn
        color="primary"
        class="icon-button my-4"
        @click="submit('EXISTING_AND_NEW_CONTACTS')"
      >
        <div class="d-flex flex-column">
          <v-icon>
            mdi-account-multiple-outline
          </v-icon>
          <p class="my-2">
            Beide
          </p>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import COMPLETE_ONBOARDING_STEP_TARGET_AUDIENCE from './queries/CompleteOnboardingStepTargetAudience.gql'
import bus, { eventNames } from '@/lib/eventBus'
import COMPANY_STATE from '@/guards/CompanyState.gql'

export default {
  methods: {
    async submit (targetAudience) {
      try {
        await this.$apollo.mutate({
          mutation: COMPLETE_ONBOARDING_STEP_TARGET_AUDIENCE,
          variables: {
            input: {
              targetAudience
            }
          },
          refetchQueries: [{ query: COMPANY_STATE, variables: { id: this.$auth.user.companyId } }]
        })
        this.$emit('completed')
      } catch (error) {
        bus.$emit(
          eventNames.SHOW_SNACKBAR,
          {
            color: 'error',
            text: 'Es gab leider ein technisches Problem beim speichern Ihrer Daten. Wir kümmern uns darum!'
          }
        )
      }
    }
  }

}
</script>

<style scoped>
.icon-button{
  width: 120px;
  min-height: 120px;
}
.icon-button-mobile{
  width: 80px;
  min-height: 80;
}

</style>
