<template>
  <div class="text-center mt-4 d-flex flex-column align-center">
    <span class="title">Wir richten die {{ readablePlatformName }}-App jetzt für Sie persönlich ein.</span>
    <v-progress-circular
      class="mt-4"
      indeterminate
    />
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'

export default {
  mixins: [brandingMixin],
  data () {
    return {
      timeout: null
    }
  },
  unmounted () {
    clearTimeout(this.timeout)
  },
  created () {
    this.timeout = setTimeout(() => {
      this.$router.push('/dashboard')
    }, 3000)
  }

}
</script>

<style>

</style>
